@use './variables';

.form-check {
  display: flex;
  min-height: $form-check-min-height;
  padding-left: $form-check-padding-start;
  margin-bottom: 0.8rem;
  align-items: center;
  justify-content: space-around;
  margin-left: 0.5rem;

  .form-check-input {
    float: left;
    margin-left: $form-check-padding-start * -1;
  }
}

.form-check-input {
  width: $form-check-input-width;
  height: $form-check-input-width;
  margin-top: ($line-height-base - $form-check-input-width) * 0.5; // line-height minus check height
  vertical-align: top;
  background-color: $form-check-input-bg;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: $form-check-input-border;
  appearance: none;
  print-color-adjust: exact; // Keep themed appearance for print
  @include transition($form-check-transition);

  &[type='checkbox'] {
    @include border-radius($form-check-input-border-radius);
  }

  &[type='radio'] {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: $form-check-radio-border-radius;
  }

  &:active {
    filter: $form-check-input-active-filter;
  }

  &:focus {
    border-color: $form-check-input-focus-border;
    outline: 0;
    box-shadow: $form-check-input-focus-box-shadow;
  }

  &:checked {
    background-color: $form-check-input-checked-bg-color;
    border-color: $form-check-input-checked-border-color;

    &[type='checkbox'] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-input-checked-bg-image),
        var(--#{$variable-prefix}gradient);
      }

      @else {
        background-image: escape-svg($form-check-input-checked-bg-image);
      }
    }

    &[type='radio'] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-radio-checked-bg-image),
        var(--#{$variable-prefix}gradient);
      }

      @else {
        background-image: escape-svg($form-check-radio-checked-bg-image);
      }
    }
  }

  &[type='checkbox']:indeterminate {
    background-color: $form-check-input-indeterminate-bg-color;
    border-color: $form-check-input-indeterminate-border-color;

    @if $enable-gradients {
      background-image: escape-svg($form-check-input-indeterminate-bg-image),
      var(--#{$variable-prefix}gradient);
    }

    @else {
      background-image: escape-svg($form-check-input-indeterminate-bg-image);
    }
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: $form-check-input-disabled-opacity;
  }

  // Use disabled attribute in addition of :disabled pseudo-class
  // See: https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~.form-check-label {
      opacity: $form-check-label-disabled-opacity;
    }
  }
}

.form-check-label {
  color: $form-check-label-color;
  cursor: $form-check-label-cursor;
}

.form-switch {
  padding-left: $form-switch-padding-start;

  .form-check-input {
    width: $form-switch-width;
    height: 1.5em;
    margin-left: $form-switch-padding-start * -1;
    background-image: escape-svg($form-switch-checked-bg-image);
    background-position: left center;
    background-color: $form-check-input-bg-color;
    border: 1px solid rgb(158, 158, 158);
    @include border-radius($form-switch-border-radius);
    @include transition($form-switch-transition);

    &:focus {
      background-image: escape-svg($form-switch-focus-bg-image);
    }


    &:checked {
      background-position: $form-switch-checked-bg-position;
      background-color: $form-checked-color;
      border-color: $form-checked-color;

      @if $enable-gradients {
        background-image: escape-svg($form-switch-checked-bg-image),
        var(--#{$variable-prefix}gradient);
      }

      @else {
        background-image: escape-svg($form-switch-checked-bg-image);
      }

      +label {
        color: $form-checked-color;
      }
    }
  }

  label {
    display: inline-block;
    margin-left: 0.5rem;
    color: #949494;
    font-family: $font-family-open;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    text-transform: capitalize;
    position: relative;
    top: 2px;
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: $form-check-inline-margin-end;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &[disabled],
  &:disabled {
    +.btn {
      pointer-events: none;
      filter: none;
      opacity: $form-check-btn-check-disabled-opacity;
    }
  }
}