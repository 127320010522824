@import '~leaflet/dist/leaflet.css';
@import '~bootstrap/scss/bootstrap';
@import './sass/variables';
@import './sass/icomoon';
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

@import './sass/utilities';
@import './sass/mixins';
@import './sass/root';
@import './sass/buttons';
@import './sass/navbar';
@import './sass/progressBar';
@import './sass/forms';
@import './sass/containers';
@import './sass/grid';
@import './sass/checkbox';
@import './sass/common';

body {
  margin: 0;
  font-family: $font-family-inter;
  color: $body-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  min-width: 320px;
}



.leaflet-vertex-icon {
  width: 0.2em !important;
  height: 0.2em !important;
  font-size: 5em;

  // cursor: url("data:image/svg+xml,%3Csvg fill='%23000000' height='16px' width='16px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 330 330' xml:space='preserve'%3E%3Cpath id='XMLID_71_' d='M325.606,154.394l-37.5-37.5c-4.289-4.291-10.744-5.572-16.347-3.252c-5.605,2.322-9.26,7.791-9.26,13.858 V150H180V67.5h22.5c6.067,0,11.536-3.655,13.858-9.26c2.321-5.605,1.038-12.057-3.252-16.347l-37.5-37.5 c-5.857-5.858-15.355-5.858-21.213,0l-37.5,37.5c-4.29,4.29-5.573,10.742-3.252,16.347c2.322,5.605,7.792,9.26,13.858,9.26H150V150 H67.5v-22.5c0-6.067-3.655-11.537-9.26-13.858c-5.606-2.321-12.058-1.038-16.347,3.252l-37.5,37.5 c-5.858,5.858-5.858,15.355,0,21.213l37.5,37.5c2.87,2.87,6.705,4.394,10.61,4.394c1.932,0,3.881-0.374,5.737-1.142 c5.605-2.322,9.26-7.792,9.26-13.858V180H150v82.5h-22.5c-6.067,0-11.537,3.655-13.858,9.26c-2.321,5.605-1.038,12.057,3.252,16.347 l37.5,37.5C157.322,328.536,161.161,330,165,330s7.678-1.464,10.606-4.394l37.5-37.5c4.29-4.29,5.573-10.742,3.252-16.347 c-2.322-5.605-7.791-9.26-13.858-9.26H180V180h82.5v22.5c0,6.067,3.654,11.537,9.26,13.858c1.855,0.769,3.805,1.142,5.737,1.142 c3.903,0,7.74-1.524,10.609-4.394l37.5-37.5C331.465,169.749,331.465,160.252,325.606,154.394z'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E") 16 16, pointer !important;
  // cursor: url("data:image/svg+xml,%3Csvg fill='%23000000' height='16px' width='16px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 330 330' xml:space='preserve'%3E%3Cpath id='XMLID_71_' d='M325.606,154.394l-37.5-37.5c-4.289-4.291-10.744-5.572-16.347-3.252c-5.605,2.322-9.26,7.791-9.26,13.858 V150H180V67.5h22.5c6.067,0,11.536-3.655,13.858-9.26c2.321-5.605,1.038-12.057-3.252-16.347l-37.5-37.5 c-5.857-5.858-15.355-5.858-21.213,0l-37.5,37.5c-4.29,4.29-5.573,10.742-3.252,16.347c2.322,5.605,7.792,9.26,13.858,9.26H150V150 H67.5v-22.5c0-6.067-3.655-11.537-9.26-13.858c-5.606-2.321-12.058-1.038-16.347,3.252l-37.5,37.5 c-5.858,5.858-5.858,15.355,0,21.213l37.5,37.5c2.87,2.87,6.705,4.394,10.61,4.394c1.932,0,3.881-0.374,5.737-1.142 c5.605-2.322,9.26-7.792,9.26-13.858V180H150v82.5h-22.5c-6.067,0-11.537,3.655-13.858,9.26c-2.321,5.605-1.038,12.057,3.252,16.347 l37.5,37.5C157.322,328.536,161.161,330,165,330s7.678-1.464,10.606-4.394l37.5-37.5c4.29-4.29,5.573-10.742,3.252-16.347 c-2.322-5.605-7.791-9.26-13.858-9.26H180V180h82.5v22.5c0,6.067,3.654,11.537,9.26,13.858c1.855,0.769,3.805,1.142,5.737,1.142 c3.903,0,7.74-1.524,10.609-4.394l37.5-37.5C331.465,169.749,331.465,160.252,325.606,154.394z'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E") -8 -8, pointer !important;

  // cursor: copy !important;
  cursor: move !important;
}

.leaflet-middle-icon {
  width: 0.2em !important;
  height: 0.2em !important;
  font-size: 5em;

  // cursor: crosshair !important;
  cursor: copy !important;
}


path.stroke-polyline {
  --size-border-plus: 1px;
  --size-border-minus: calc(-1 * var(--size-border-plus));
  filter:
    drop-shadow(var(--size-border-plus) var(--size-border-plus) 0 #fff) drop-shadow(var(--size-border-minus) var(--size-border-minus) 0 #FFF) drop-shadow(var(--size-border-plus) var(--size-border-minus) 0 #FFF) drop-shadow(var(--size-border-minus) var(--size-border-plus) 0 #fff);
}